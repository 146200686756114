<template>
  <div class="form-content bg-white border-radius-10">
    <h6 class="text-danger pa-1">Fields marked with (*) are mandatory</h6>
    <vs-row vs-align="center" vs-type="flex" vs-justify="space-between" vs-w="12" class="mt-4">
      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <!-- English NAME -->
        <vs-input
          label="English Name *"
          v-model="form.name_en"
          class="mt-5 w-full"
          name="name_en"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('name_en')"
        >{{ errors.first("name_en") }}</span>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <!-- Arabic NAME -->
        <vs-input
          label="Arabic Name *"
          v-model="form.name_ar"
          class="mt-5 w-full"
          name="name_ar"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('name_ar')"
        >{{ errors.first("name_ar") }}</span>
      </vs-col>

      <vs-col class="pa-4" vs-w="6">
        <label>Brand *</label>
        <select v-model="form.brand_id" style="height: 40px">
          <option value="0" selected disabled>Choose Brand</option>
          <option
            v-for="(brand, index) in brands"
            :key="index"
            :value="brand.id"
          >{{brand.name_en}}
          </option>
        </select>
      </vs-col>

      <vs-col class="pa-4" vs-w="6">
        <label>Car Type *</label>
        <select v-model="form.car_type_id" style="height: 40px">
          <option value="0" selected disabled>Choose Car Type</option>
          <option
            v-for="(carType, index) in carTypes"
            :key="index"
            :value="carType.id"
          >{{carType.name_en}}
          </option>
        </select>
      </vs-col>

      <vs-col class="pa-4" vs-w="6">
        <label>Year *</label>
        <select v-model="form.year" style="height: 40px">
          <option value="0" selected disabled>Choose Year</option>
          <option v-for="(year,index) in years" :key="index" :value="year">{{year}}</option>
        </select>
      </vs-col>

      <vs-col class="pa-4" vs-w="6">
        <label>Type *</label>
        <select v-model="form.car_type" style="height: 40px">
          <option value="0">Passenger</option>
          <option value="1">Truck</option>
        </select>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <!--En Title warranty-->
        <vs-input
          label="Warranty English Title*"
          min="0"
          v-model="form.warranties.en.title"
          class="mt-5 w-full"
          name="warranty.en.title"
        />
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <!--En Title warranty-->
        <vs-input
          label="Warranty Arabic Title*"
          min="0"
          v-model="form.warranties.ar.title"
          class="mt-5 w-full"
          name="warranty.en.title"
        />
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <text-area
          label="Warranty English Details*"
          v-model="form.warranties.en.text"
          rows="12"
          :name="`warranty.en.text`"
        />
      </vs-col>

      <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
        <text-area
          label="Warranty Arabic Details*"
          v-model="form.warranties.ar.text"
          rows="12"
          :name="`warranty.ar.text`"
        />
      </vs-col>

      <!--            <vs-col class="pa-4" vs-w="6">-->
      <!--                <label for="folders">Attach Gallery*</label>-->
      <!--                <select id="folders" v-model="form.imagesFolder" style="height: 40px">-->
      <!--                    <option value="0" selected disabled>Choose Gallery</option>-->
      <!--                    <option-->
      <!--                        v-for="(folder, index) in imageFolders"-->
      <!--                        :key="index"-->
      <!--                        :value="folder.id"-->
      <!--                    >{{folder.name}}</option>-->
      <!--                </select>-->
      <!--            </vs-col>-->

      <vs-col vs-type="flex" class="pa-4" vs-align="center" vs-w="12" style="margin-top: 20px">
        <label class="vs-input--label">Show on Mobile*</label>
        <vs-switch v-model="form.mobile_active"/>
      </vs-col>

      <div style="display: flex; flex-direction: row; justify-content:flex-start">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
          <upload-file
            :limit="1"
            text="Choose Image*"
            :show-upload-button="false"
            @getFile="uploadImage"
          />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="6">
          <upload-file
            :limit="1"
            accept="application/pdf, image/*"
            text="Upload Brochure*"
            :show-upload-button="false"
            @getFile="uploadBrochure"
          />
        </vs-col>
      </div>
    </vs-row>

    <div class="submit-actions">
      <vs-button
        class="mr-6 save"
        @click="submitData"
        icon-pack="feather"
        icon="icon-save"
        :disabled="!isFormValid"
      >Save
      </vs-button>
      <vs-button type="border" @click="$emit('cancel')">Cancel</vs-button>
    </div>
  </div>
</template>

<script>
  import UploadFile from "../../../../components/uploader/upload-file";
  import TextArea from "../../../../components/form/text-area";
  import { getYears } from "../../../../utils/functions";
  import Services from "../../../../components/form/model-services/services";

  export default {
    name: "Create",
    components: { Services, TextArea, UploadFile },
    data() {
      return {
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        imageFolders: [],
        form: {
          imagesFolder: "0",
          brand_id: "0",
          car_type_id: "0",
          name_en: "",
          name_ar: "",
          image: null,
          year: "0",
          car_type: 0,
          brochure: null,
          mobile_active: false,
          services: [
            {
              km: 0,
              price: 0,
              details: [
                {
                  en: "",
                  ar: ""
                }
              ]
            }
          ],
          warranties: {
            ar: {
              title: "",
              text: ""
            },
            en: {
              title: "",
              text: ""
            }
          }
        },
        years: [],
        brands: [],
        carTypes: []
      };
    },
    computed: {
      isFormValid() {
        return (
          !this.errors.any() &&
          this.form.brand_id &&
          this.form.car_type_id &&
          this.form.name_en &&
          this.form.name_ar &&
          this.form.year &&
          this.form.image &&
          this.form.warranties.en.title.length > 0 &&
          this.form.warranties.ar.text.length > 0 &&
          this.form.warranties.en.title.length > 0 &&
          this.form.warranties.ar.text.length > 0
        );
      }
    },
    methods: {
      uploadImage(event) {

        this.$vs.loading();

        let formData = new FormData();
        formData.append("image", event);
        formData.append("category", "config");

        this.$httpAdmin
          .post("config/upload-media", formData)
          .then(r => {
            this.$vs.loading.close();
            this.form.image = r.data.data;
            console.log('Image Link: ', this.form.image);
            this.$vs.notify({
              title: "Success",
              text: "Image is uploaded successfully!",
              color: "success"
            });
          })
          .catch((e) => {
            this.$vs.loading.close();
            console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't upload image!",
              color: "danger"
            });
          });
      },
      uploadBrochure(event) {
        this.$vs.loading();

        let formData = new FormData();
        formData.append("image", event);
        formData.append("category", "config");

        this.$httpAdmin
          .post("config/upload-media", formData)
          .then(r => {
            this.$vs.loading.close();
            this.form.brochure = r.data.data;
            console.log('Brochure Link: ', this.form.brochure);
            this.$vs.notify({
              title: "Success",
              text: "Brochure is uploaded successfully!",
              color: "success"
            });
          })
          .catch((e) => {
            this.$vs.loading.close();
            console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't upload brochure!",
              color: "danger"
            });
          });
      },
      getFolders() {
        this.$httpCars
          .get("gallery-folders")
          .then(r => {
            this.$vs.loading.close();
            let total = r.data.data.total;
            console.log("total", total);
            this.$httpCars
              .get(`gallery-folders?per_page=${total}`)
              .then(r => {
                this.$vs.loading.close();
                this.imageFolders = r.data.data.data;
                console.log('Galleries: ', this.imageFolders);
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
      getAllBrands() {
        this.$vs.loading();
        this.$httpCars
          .get("/brands?ber_page=1000")
          .then(res => {
            this.brands = res.data.data.brands;
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log({ e });
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch brands from the server",
              color: "danger"
            });
          });
      },
      getAllCarTypes() {
        this.$vs.loading();
        this.$httpCars
          .get("/car-types?ber_page=1000")
          .then(res => {
            this.carTypes = res.data.data.car_types;
            this.$vs.loading.close();
          })
          .catch(() => {
            //console.log({ e });
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch car types from the server",
              color: "danger"
            });
          });
      },
      addService() {
        this.form.services.push({
          km: 0,
          price: 0,
          details: [
            {
              en: "",
              ar: ""
            }
          ]
        });
      },
      removeFromService(index) {
        this.form.services = this.form.services.filter((_, i) => {
          return index !== i;
        });
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            const obj = this.form;
            const formData = new FormData();

            formData.append("name_en", obj.name_en);
            formData.append("name_ar", obj.name_ar);
            formData.append("brand_id", obj.brand_id);
            formData.append("car_type_id", obj.car_type_id);
            formData.append("year", obj.year);
            formData.append("car_type", obj.car_type);
            formData.append("image", obj.image);
            formData.append("brochure", obj.brochure);
            // formData.append("model_gallery_id", obj.imagesFolder);
            formData.append("mobile_active", Number(obj.mobile_active));

            for (let i in obj.services) {
              if (
                obj.services[i].km > 0 &&
                obj.services[i].price > 0 &&
                obj.services[i].details.length > 0
              ) {
                formData.append(
                  `services[${i}][km]`,
                  obj.services[i].km
                );
                formData.append(
                  `services[${i}][price]`,
                  obj.services[i].price
                );
                for (let j in obj.services[i].details) {
                  if (
                    obj.services[i].details[j].ar.length > 0 &&
                    obj.services[i].details[j].en.length > 0
                  ) {
                    formData.append(
                      `services[${i}][details][ar][${j}]`,
                      obj.services[i].details[j].ar
                    );
                    formData.append(
                      `services[${i}][details][en][${j}]`,
                      obj.services[i].details[j].en
                    );
                  }
                }
              }
            }

            formData.append(
              `warranties[ar][title]`,
              obj.warranties.ar.title
            );
            formData.append(
              `warranties[ar][text]`,
              obj.warranties.ar.text
            );
            formData.append(
              `warranties[en][title]`,
              obj.warranties.en.title
            );
            formData.append(
              `warranties[en][text]`,
              obj.warranties.en.text
            );
            this.$httpCars
              .post("/models", formData)
              .then(res => {
                this.$vs.loading.close();
                this.$emit("onSave");
                this.$vs.notify({
                  title: "Successful operation",
                  text: "Model has been created successfully",
                  color: "success"
                });
                const id = res.data.data.data.id;
                for (let i in this.addsonArr) {
                  if (
                    this.addsonArr[i].adon_id &&
                    this.addsonArr[i].price > 0 &&
                    id
                  ) {
                    this.$http
                      .post("/adons/add-adon-to-model", {
                        model_id: id,
                        adon_id: this.addsonArr[i].adon_id,
                        price: this.addsonArr[i].price
                      })
                      .then(() => {
                        this.$vs.loading.close();
                      })
                      .catch(() => {
                        //console.log({ e });
                        this.$vs.notify({
                          title: "Something Error 🙁",
                          text:
                            "Couldn't assign adds on to model in the server",
                          color: "danger"
                        });
                      });
                  }
                }
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error 🙁",
                  text: "Couldn't add model to the server",
                  color: "danger"
                });
                //console.log({ error });
              });
          }
          this.$vs.loading.close();
        });
      }
    },
    mounted() {
      this.years = getYears();
      this.getAllBrands();
      this.getAllCarTypes();
      this.getFolders();
    }
  };
</script>

<style scoped lang="scss">
  label {
    font-size: 1rem;
    padding: 5px;
  }

  select {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #00000030;
  }
</style>
